import request from '@/utils/request'

// 查询所有产品
export function findProducts(params) {
  return request({
    url: '/products',
    method: 'get',
    params: params
  })
}

// 查询产品类型选项
export function findProductTypeOptions() {
  return request({
    url: '/products/type/options',
    method: 'get'
  })
}

// 查询订购产品表单数据
export function findSubscribeProductForm(id, params) {
  return request({
    url: `/products/${id}/subscribe/form`,
    method: 'get',
    params: params
  })
}

// 订购产品
export function subscribeProduct(id, data) {
  return request({
    url: `/products/${id}/subscribe`,
    method: 'post',
    data
  })
}
