<template>
  <div>
    <a-form :form="form" @submit="handleSearch" class="search-form">
      <a-form-item>
        <a-input
          v-decorator="['name', {
            normalize: this.$lodash.trim
          }]"
          placeholder="请输入产品名称"
          allow-clear
        />
      </a-form-item>

      <a-form-item>
        <a-space>
          <a-button type="primary" html-type="submit" class="search-btn">
            搜索
          </a-button>
          <a-button @click="handleReset">
            刷新
          </a-button>
        </a-space>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
export default {
  name: 'SearchProduct',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'product_search' })
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>

<style lang="less" scoped>
.search-form {
  display: flex;

  .search-btn {
    margin-left: 10px;
  }
}
</style>
