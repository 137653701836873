<template>
  <div class="product-info">
    <div class="product-header">
      <h1>订购产品({{ data.total_count }})</h1>
      <search @submit="submitSearch" />
    </div>

    <div class="product">
      <div
        v-for="(product, stateIndex) in products"
        :key="stateIndex"
        class="product-content-box"
      >
        <div class="product-content">
          <img
            v-show="product.images && product.images.length > 0"
            class="product-img"
            alt="img"
            v-for="(item, index) in product.images"
            :key="index"
            :src="item.url"
          >
          <img
            v-show="!product.images || product.images.length === 0"
            class="product-img"
            alt="img"
            src="/temp/no_picture.png"
          >
          <div class="product-name custom-word-break-all">
            {{ product.name }}
          </div>
          <div class="price">
            {{ product.price }}
          </div>
          <div class="sub-button" @click="showSubscribeModal(product.id)">订购</div>
        </div>
      </div>
    </div>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <subscribe
      v-if="isShowSubscribeModal"
      :visible.sync="isShowSubscribeModal"
      :id="subscribeProductId"
      :service-order-id="serviceOrderId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import { findProducts } from '@/api/product'
import Search from '@/views/products/Search'
import Pagination from '@/components/Pagination'
export default {
  name: 'ProductIndex',
  components: {
    Pagination,
    Search,
    Subscribe: () => import('@/views/products/Subscribe')
  },
  data() {
    return {
      data: {},
      products: [],
      isShowSubscribeModal: false,
      subscribeProductId: 0,
      query: {
        per_page: 8
      },
      pagination: {
        total_count: 0
      }
    }
  },
  computed: {
    hallId() {
      return parseInt(this.$route.params.id)
    },

    serviceOrderId() {
      return parseInt(this.$route.query.service_order_id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findProducts(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.products = res.data.data
          this.pagination = res.pagination
        }
      })
    },

    showSubscribeModal(id) {
      this.isShowSubscribeModal = true
      this.subscribeProductId = id
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    }
  }
}
</script>
<style lang="less" scoped>
.product-info {
  overflow-y: auto;
  height: 100%;
}

.product-header {
  display: flex;
  justify-content: space-between;

  h1 {
    color: @white;
  }
}

.product {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .product-content-box {
    flex-basis: 25%;
  }
}

.product-content {
  display: flex;
  margin-bottom: 20px;
  width: 150px;
  flex-direction: column;

  .product-img {
    width: 100%;
    height: 120px;
    border-radius: 5px;
  }

  .product-name {
    margin-top: 5px;
    font-size: 16px;
    color: @white;
  }

  .price {
    margin-top: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #ffd794ff;
  }

  .sub-button {
    margin-top: 10px;
    width: 45px;
    height: 23px;
    border: 1px solid;
    border-radius: 10px;
    text-align: center;
    color: #ffd794b0;
    cursor: pointer;
  }
}

</style>
